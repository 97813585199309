import { IconButton } from '@mui/material';
import { ExpandMenuIcon } from 'components/icons';
import { FC } from 'react';
import MenuPopover from './MenuPopover';

type Props = {
  actions: React.ReactNode;
  open: boolean;
  anchorEl?: HTMLElement | null;
  onClose?: VoidFunction;
  onOpen?: (event: React.MouseEvent<HTMLElement>) => void;
};

const TableMoreMenu: FC<Props> = ({ actions, open, anchorEl, onClose, onOpen }) => (
  <>
    <IconButton onClick={onOpen}>
      <ExpandMenuIcon width={20} height={20} />
    </IconButton>

    <MenuPopover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      arrow="right-top"
      sx={{
        mt: -1,
        width: 'max-content',
        '& .MuiMenuItem-root': {
          px: 1,
          '& svg': { mr: 2, width: 20, height: 20 },
        },
      }}
    >
      {actions}
    </MenuPopover>
  </>
);

export default TableMoreMenu;
