import { CheckRoundedIcon, ClockFilledIcon, ErrorIconMUI, InfoIconMUI2 } from 'components/icons';
import { includes } from 'lodash';
import { GetPanelistConnectionStatusResponse } from 'types/api/pharmacies/panelist_connection';
import { ConnectionEstablishedBanner } from './banners/ConnectionEstablishedBanner';
import { ConnectionEstablishingBanner } from './banners/ConnectionEstablishingBanner';
import { ConnectionNotEstablishedBanner } from './banners/ConnectionNotEstablishedBanner';

type PanelistConnectionStatusIconProps = {
  fontSize: 'small' | 'medium';
  panelistConnection: GetPanelistConnectionStatusResponse;
};

type PanelistConnectionStatusBannerProps = {
  panelistConnection: GetPanelistConnectionStatusResponse;
};

export const PanelistConnectionStatusIcon = ({
  panelistConnection,
  fontSize,
}: PanelistConnectionStatusIconProps) => {
  const { is_lgo_connection_allowed, pharmacy_panelist } = panelistConnection;

  if (!is_lgo_connection_allowed) return null;

  if (!pharmacy_panelist?.panelist)
    return <InfoIconMUI2 fontSize={fontSize} sx={{ marginRight: 1 }} color="warning" />;

  if (includes(['sharing_not_approved', 'not_client'], pharmacy_panelist.status))
    return <ErrorIconMUI fontSize={fontSize} sx={{ marginRight: 1 }} color="error" />;

  if (pharmacy_panelist.status === 'established')
    return <CheckRoundedIcon fontSize={fontSize} sx={{ marginRight: 1 }} color="success" />;

  if (pharmacy_panelist.status === 'establishing')
    return <ClockFilledIcon fontSize={fontSize} sx={{ marginRight: 1 }} color="warning" />;

  return null;
};

export const PanelistConnectionStatusBanner = ({
  panelistConnection,
}: PanelistConnectionStatusBannerProps) => {
  const { is_lgo_connection_allowed, pharmacy_panelist } = panelistConnection;

  if (!is_lgo_connection_allowed || !pharmacy_panelist?.panelist) return null;

  if (includes(['sharing_not_approved', 'not_client'], pharmacy_panelist.status))
    return <ConnectionNotEstablishedBanner pharmacyPanelist={pharmacy_panelist} />;

  if (pharmacy_panelist.status === 'established') return <ConnectionEstablishedBanner />;

  if (pharmacy_panelist.status === 'establishing')
    return <ConnectionEstablishingBanner pharmacyPanelist={pharmacy_panelist} />;

  return null;
};
