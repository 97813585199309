import moment from 'moment';

export const PANELISTS = ['offisante', 'ospharm', 'santestat'] as const;
export const PANELIST_REQUEST_ERROR_CODES = [
  'missing_sellout_data_sharing_approval',
  'pharmacy_not_panelist_client',
  'missing_approval_or_not_client',
] as const;
export const PANELIST_REQUEST_STATUSES = ['ongoing', 'done', 'error', 'expired'];
export const LGO_APPLICATION_DATE = moment('2023-01-01');
