import { SxProps, Theme, Typography, TypographyTypeMap, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { ColorSchema } from '../../theme/palette';
import { BannerContainer, StyledIcon } from './style';

interface Props {
  title: string | ReactNode;
  icon?: ReactNode;
  color?: ColorSchema | 'grey';
  sx?: SxProps<Theme>;
  textProps?: TypographyTypeMap['props'];
}

export const Banner = ({ title, icon, color = 'primary', sx, textProps }: Props) => {
  const theme = useTheme();
  const isGrey = color === 'grey';

  return (
    <BannerContainer
      sx={{
        color: isGrey ? theme.palette.grey['700'] : theme.palette[color].darker,
        backgroundColor: isGrey ? theme.palette.grey['300'] : theme.palette[color].lighter,
        ...sx,
      }}
    >
      {icon && (
        <StyledIcon fillColor={isGrey ? theme.palette.grey['500'] : theme.palette[color].main}>
          {icon}
        </StyledIcon>
      )}

      <Typography flexGrow={1} variant="subtitle2" sx={{ opacity: 0.72 }} {...textProps}>
        {title}
      </Typography>
    </BannerContainer>
  );
};
