import { Stack } from '@mui/material';
import { ChevronLeftIcon } from 'components/icons';
import { useTranslation } from 'locales/i18n';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'redux/slices/auth';
import { dispatch } from 'redux/store';
import { StyledLink } from './styles';

type Props = {
  shouldSignOut: boolean;
  onSignOutNavigateTo?: string;
};

export const PageLayoutCancelLink = ({ shouldSignOut, onSignOutNavigateTo }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <StyledLink
      onClick={
        shouldSignOut
          ? () => {
              dispatch(signOut({}));
              if (onSignOutNavigateTo) navigate(onSignOutNavigateTo);
            }
          : () => navigate(-1)
      }
    >
      <Stack direction="row" alignItems="center">
        <ChevronLeftIcon /> {shouldSignOut ? t('shared.disconnection') : t('shared.back')}
      </Stack>
    </StyledLink>
  );
};
