import { isNaN } from 'lodash';

export const stringUtils = {
  isDefined: (s: string | null | undefined): boolean => s !== null && s !== undefined && s !== '',
  undefinedIfEmpty: (s: string | undefined): string | undefined => (s === '' ? undefined : s),
  toNumberOrNull: (s: string | undefined): number | null => {
    if (!s) return null;
    else if (isNaN(Number(s))) return null;
    return Number(s);
  },
  isStringNumerical: (value?: string): boolean => {
    return /^-?\d+([.,]\d*)?$/.test(value || '');
  },
};
