import { isEmpty } from 'lodash';

export const readOnly = <T extends string>(arr: readonly T[]) => arr;

export const splitAt = <T>(arr: T[], index: number) => [arr.slice(0, index), arr.slice(index)];

export const undefinedIfEmpty = <T>(arr: T[]) => (isEmpty(arr) ? undefined : arr);

export const getDuplicatedValues = <T>(arr: T[]): T[] =>
  arr.filter((value, index, array) => array.indexOf(value) !== index);

export const moveItemInArray = <T>(arr: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(arr);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
