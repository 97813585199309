import { Stack } from '@mui/material';
import { ArrowLeftIcon } from 'components/icons';
import { useTranslation } from 'locales/i18n';
import { FC } from 'react';
import { useNavigate, useNavigationType } from 'react-router-dom';
import { StyledLink } from './styles';

type Props = {
  goBackUrl?: string;
  color?: string;
};

export const GoBackLink: FC<Props> = ({ goBackUrl, color }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  const handleNavigation = () => {
    if (goBackUrl) {
      return navigate(goBackUrl);
    }
    // Si le type de navigation est "POP", cela signifie qu'il n'y a pas d'historique
    if (navigationType === 'POP') {
      navigate('/');
    } else {
      navigate(-1);
    }
  };

  return (
    <StyledLink onClick={handleNavigation}>
      <Stack direction="row" alignItems="center" color={color}>
        <ArrowLeftIcon fontSize="small" /> {t('shared.goBack')}
      </Stack>
    </StyledLink>
  );
};
