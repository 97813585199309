import { formatDistance } from 'date-fns';
import { fr } from 'date-fns/locale';
import moment from 'moment';

export const formatSimpleDate = (date?: Date | string | null): string | undefined =>
  date ? moment(typeof date === 'string' ? new Date(date) : date).format('DD/MM/YYYY') : undefined;

export const formatSimpleDateTime = (date?: Date | string | null): string | undefined =>
  date
    ? moment(typeof date === 'string' ? new Date(date) : date).format('DD/MM/YYYY, HH:mm')
    : undefined;

export const formatElapsedTime = (minutes: number): string | undefined =>
  formatDistance(new Date(0), new Date(0).setSeconds(minutes), { locale: fr });

export const getLastDayOfMonth = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth() + 1, 0);

export const checkIfDatesAreEqual = (dateA: Date, dateB: Date) =>
  moment(dateA).format('LL') === moment(dateB).format('LL');

export const definedAndBeforeToday = (date: string | null): boolean => {
  const now = moment();
  return !!date && moment(date).isBefore(now, 'day');
};

export const undefinedOrBeforeNow = (date: string | null): boolean => {
  const now = moment();
  return !date || moment(date).isBefore(now);
};

export const definedAndAfterNow = (date: string | null): boolean => {
  const now = moment();
  return !!date && moment(date).isAfter(now);
};

export const oneDayFromNow = () => {
  const now = new Date();
  now.setHours(now.getHours() + 24);
  return now;
};
