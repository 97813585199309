import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { useCreateMutation } from 'api';
import { PhoneInputField } from 'components/PhoneInputField';
import { FormProvider, TextField } from 'components/hook-form';
import { RHFSelect } from 'components/hook-form/RHFSelect';
import { useTranslation } from 'locales/i18n';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { LoginPaths } from 'routes/paths';
import { AskForInvitationParams } from 'types/api/user';
import { readOnly } from 'utils/arrayUtils';
import { validatePhoneNumber } from 'utils/phoneUtils';
import { emailValidator, requiredString } from 'utils/yupValidators';
import * as Yup from 'yup';

type FormValuesProps = {
  email: string;
  phoneNumber: string;
  userType: AskForInvitationParams['user']['type'];
  company: string;
  position: string;
};

export const Form: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const SigninSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required()
      .test(
        'phoneNumber',
        t('login.verify_phone_number.form.phone_number_format_error'),
        validatePhoneNumber
      ),
    email: emailValidator('email'),
    userType: requiredString(t('invitation.userTypeRequired')),
  });

  const defaultValues = {
    email: '',
    phoneNumber: '',
    userType: undefined,
    company: '',
    position: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(SigninSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  const createInvitation = useCreateMutation('user/ask_for_invitation');

  const onSubmit = (data: FormValuesProps) =>
    createInvitation
      .mutateAsync({
        user: {
          email: data.email,
          type: data.userType,
          phone_number: data.phoneNumber,
          company: data.company,
          position: data.position,
        },
      })
      .then(() => {
        toast.success(t('invitation.invitationSent'));
        navigate(LoginPaths.Email);
      })
      .catch((error) => {
        if (error.response?.data?.error === 'user_already_exist')
          toast.error(t('invitation.accountExist'));
      });

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <TextField
          name="email"
          label={t('login.verify_email.form.your_email')}
          placeholder={t('login.verify_email.form.enter_your_email')}
          required
        />
        <PhoneInputField label={t('login.verify_phone_number.form.your_phone_number')} />
        <RHFSelect
          required
          name="userType"
          options={readOnly(['group', 'lab', 'logistician', 'wholesaler', 'other']).map(
            (userType) => ({
              value: userType,
              title: t(`shared.${userType}`),
            })
          )}
          label={t('invitation.youAre')}
          placeholder={t('invitation.youAre')}
        />
        <TextField
          name="company"
          label={t('invitation.yourCompany')}
          placeholder={t('invitation.enterYourCompany')}
          required
        />
        <TextField
          name="position"
          label={t('invitation.yourPosition')}
          placeholder={t('invitation.enterYourPosition')}
          required
        />
      </Stack>
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={createInvitation.isLoading}
        sx={{ mt: 3 }}
      >
        {t('shared.send')}
      </LoadingButton>
      <Button size="large" variant="text" onClick={() => navigate(-1)} fullWidth sx={{ mt: 2 }}>
        {t('shared.back')}
      </Button>
    </FormProvider>
  );
};

export default Form;
