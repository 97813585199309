import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'locales/i18n';

type Props = {
  onClose: VoidFunction;
  isLoading: boolean;
};

export const FormCancelAndSaveButtons = ({ onClose, isLoading }: Props) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" gap={2} justifyContent="flex-end">
      <Button variant="contained" color="inherit" onClick={onClose}>
        {t('shared.cancel')}
      </Button>
      <LoadingButton variant="contained" type="submit" loading={isLoading}>
        {t('shared.save')}
      </LoadingButton>
    </Stack>
  );
};
