import Iconify from 'components/Iconify';
import { FC } from 'react';

interface Props {
  isCheckmarkVisible: boolean;
}

export const CheckmarkIcon: FC<Props> = ({ isCheckmarkVisible }) => (
  <Iconify
    icon={isCheckmarkVisible ? 'eva:checkmark-circle-fill' : 'akar-icons:circle-x'}
    sx={{
      width: 20,
      height: 20,
      color: isCheckmarkVisible ? 'success.main' : 'error.main',
    }}
  />
);
