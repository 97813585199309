import { Box, Divider, Link, ListItemText, Stack, Tooltip, Typography } from '@mui/material';
import { ChevronDownIcon, ChevronRightIcon } from 'components/icons';
import { useTrackEvent } from 'hooks/useTrackEvent';
import { NavLink as RouterLink } from 'react-router-dom';
import { SharedPaths } from 'routes/paths';
import { TrackedActions } from 'utils/trackingUtils';
import { isExternalLink } from './navSectionUtils';
import { ListItemStyle as ListItem, ListItemIconStyle, ListItemTextStyle } from './style';
import { NavItemProps } from './type';

export function NavItemRoot({ item, open = false, active, onOpen }: NavItemProps) {
  const trackEvent = useTrackEvent();

  const {
    title,
    tooltip,
    path,
    icon,
    info,
    children,
    disabled,
    caption,
    roles,
    rightIcon,
    hasDivider,
  } = item;

  const trackExternalNavigate = () => {
    if (path === SharedPaths.IntercomFAQ) {
      trackEvent(TrackedActions.intercomFAQ);
    }
  };

  const renderContent = (
    <>
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
      <ListItemTextStyle
        disableTypography
        primary={title}
        secondary={
          <Tooltip title={caption || ''} arrow>
            <Typography
              noWrap
              variant="caption"
              component="div"
              sx={{ textTransform: 'initial', color: 'text.secondary' }}
            >
              {caption}
            </Typography>
          </Tooltip>
        }
      />
      {rightIcon}
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItem onClick={onOpen} activeRoot={active} disabled={disabled} roles={roles}>
        {renderContent}
      </ListItem>
    );
  }

  return (
    <Tooltip title={tooltip}>
      {isExternalLink(path) ? (
        <ListItem
          component={Link}
          href={path}
          target="_blank"
          rel="noopener"
          disabled={disabled}
          roles={roles}
          onClick={trackExternalNavigate}
          sx={{ width: 'unset' }}
        >
          {renderContent}
        </ListItem>
      ) : (
        <Stack>
          <ListItem
            component={RouterLink}
            to={path}
            activeRoot={active}
            disabled={disabled}
            roles={roles}
          >
            {renderContent}
          </ListItem>
          {hasDivider && <Divider sx={{ m: 1 }} />}
        </Stack>
      )}
    </Tooltip>
  );
}

type NavItemSubProps = Omit<NavItemProps, 'isCollapse'>;

export function NavItemSub({ item, open = false, active = false, onOpen }: NavItemSubProps) {
  const { title, path, info, children, disabled, caption, roles, rightIcon, tooltip } = item;

  const renderContent = (
    <>
      <DotIcon active={active} />
      <ListItemText
        disableTypography
        primary={title}
        secondary={
          <Tooltip title={caption || ''} arrow>
            <Typography
              noWrap
              variant="caption"
              component="div"
              sx={{ textTransform: 'initial', color: 'text.secondary' }}
            >
              {caption}
            </Typography>
          </Tooltip>
        }
      />
      {info && info}
      {rightIcon}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItem onClick={onOpen} activeSub={active} subItem disabled={disabled} roles={roles}>
        {renderContent}
      </ListItem>
    );
  }

  return (
    <Tooltip title={tooltip}>
      {isExternalLink(path) ? (
        <ListItem
          component={Link}
          href={path}
          target="_blank"
          rel="noopener"
          subItem
          disabled={disabled}
          roles={roles}
        >
          {renderContent}
        </ListItem>
      ) : (
        <ListItem
          component={RouterLink}
          to={path}
          activeSub={active}
          subItem
          disabled={disabled}
          roles={roles}
        >
          {renderContent}
        </ListItem>
      )}
    </Tooltip>
  );
}

type DotIconProps = {
  active: boolean;
};

export function DotIcon({ active }: DotIconProps) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconStyle>
  );
}

type ArrowIconProps = {
  open: boolean;
};

export function ArrowIcon({ open }: ArrowIconProps) {
  return open ? (
    <ChevronDownIcon sx={{ width: 16, height: 16, ml: 1 }} />
  ) : (
    <ChevronRightIcon sx={{ width: 16, height: 16, ml: 1 }} />
  );
}
