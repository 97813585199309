import { t } from 'locales/i18n';
import React from 'react';

export const shouldNotHappen = (_v: never, signature: string): never => {
  throw new Error(`should not happen: [${signature}]`);
};

export const moveItemInList = <T>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const booleanToString = (value: boolean) => {
  return value ? t('shared.yes') : t('shared.no');
};

// source : https://www.totaltypescript.com/forwardref-with-generic-components
export const forwardRefForGeneric = <T, P extends {}>(
  render: (props: P, ref: React.Ref<T>) => React.ReactNode
): ((props: P & React.RefAttributes<T>) => React.ReactNode) => {
  return React.forwardRef(render) as any;
};
