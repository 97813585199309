import {
  Group,
  Paginated,
  Pharmacy,
  Role,
  Trade,
  TradeInstance,
  TradesInstanceStatus,
} from 'types/models';

export type GetAdminTradesInstancesParams = {
  page: number;
  trade_id?: number;
  query?: string;
  status?: TradesInstanceStatus;
};

export type AdminTradeInstance = TradeInstance & {
  pharmacy: Pharmacy & { group: Group | null };
  trade: Trade;
};

export type GetAdminTradesInstancesResponse = Paginated<{ trades_instances: AdminTradeInstance[] }>;

export type AdminTradeInstanceShow = TradeInstance & {
  pharmacy: Pharmacy & {
    role_to_impersonate: Role | null;
    group: Group | null;
  };
  trade: {
    role_to_impersonate: Role | null;
    lab_trade: Trade | null;
  };
};

export type GetAdminTradesInstanceResponse = {
  trades_instance: AdminTradeInstanceShow & { has_trades_requirements_instances: boolean };
};

export type DeleteAdminTradeInstanceResponse = {
  success: boolean;
};

export const PanelistExpectedResultValues = [
  'mark_as_done',
  'mark_as_expired',
  'mark_as_pharmacy_not_panelist_client_first_occurrence',
  'mark_as_pharmacy_not_panelist_client_retry_expired',
  'mark_as_missing_sellout_data_sharing_approval_first_occurrence',
  'mark_as_missing_sellout_data_sharing_approval_retry_expired',
  'mark_as_missing_approval_or_not_client_first_occurrence',
  'mark_as_missing_approval_or_not_client_retry_expired',
  'reset',
] as const;

export type PanelistExpectedResult = (typeof PanelistExpectedResultValues)[number];

export const PanelistErrorMessageValues = [
  'instance_not_found',
  'instance_not_subscribed',
  'sellout_panelist_request_already_exists',
  'trade_lgo_data_upload_disabled',
  'trade_without_sellout_requirement',
  'trade_without_trades_products',
  'pharmacy_without_lgo_activated',
] as const;

export type PanelistErrorMessage = (typeof PanelistErrorMessageValues)[number];

export type CreateFakePanelistRequestParams = {
  trades_instance_id: number;
  expected_result: PanelistExpectedResult;
};

export type CreateFakePanelistRequestResponse = {
  error_message?: PanelistErrorMessage;
  success?: boolean;
};

export const FaksValidationExpectedResultValues = [
  'send_to_validation',
  'validate_participation',
  'refuse_participation',
  'reset',
] as const;

export type FaksValidationExpectedResult = (typeof FaksValidationExpectedResultValues)[number];

export const FaksValidationErrorMessageValues = [
  'instance_not_found',
  'instance_not_awaiting_validation',
  'faks_validation_demand_already_exists',
  'faks_validation_demand_dont_exists',
  'lab_not_authorized_for_faks_validation',
  'faks_validation_not_enabled_for_this_trade',
  'trade_without_trades_products',
  'trade_without_lab_trade',
  'trade_with_incompatible_requirement',
  'missing_sellout_pdf',
  'missing_mea_validation_brief',
] as const;

export type FaksValidationErrorMessage = (typeof FaksValidationErrorMessageValues)[number];

export type CreateFakeFaksValidationRequestParams = {
  trades_instance_id: number;
  expected_result: FaksValidationExpectedResult;
};

export type CreateFakeFaksValidationResponse = {
  error_message?: FaksValidationErrorMessage;
  success?: boolean;
};
