import { Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { Banner } from 'components/banner/banner';
import { ErrorIconMUI } from 'components/icons';
import { t } from 'locales/i18n';
import { Trans } from 'react-i18next';
import { PharmacyPanelist } from 'types/api/pharmacies/panelist_connection';
import { PanelistConnectionSteps } from '../PanelistConnectionSteps';

type Props = {
  pharmacyPanelist: PharmacyPanelist;
};

export const ConnectionNotEstablishedBanner = ({ pharmacyPanelist }: Props) =>
  pharmacyPanelist.panelist &&
  (pharmacyPanelist.status === 'sharing_not_approved' ||
    pharmacyPanelist.status === 'not_client') && (
    <Banner
      icon={<ErrorIconMUI fontSize="medium" sx={{ marginRight: 1 }} />}
      title={
        <Stack gap={2}>
          <Box>
            <Typography variant="subtitle2">
              {t(`pharmacyLgoConnection.connectionNotAvailable.${pharmacyPanelist.status}`)}
            </Typography>
          </Box>
          <Box>
            <PanelistConnectionSteps
              panelist={pharmacyPanelist.panelist}
              onboardingUrl={pharmacyPanelist.onboarding_url}
            />
            {pharmacyPanelist.panelist === 'offisante' &&
              pharmacyPanelist.status === 'sharing_not_approved' && (
                <Box pt={2}>
                  <Trans
                    i18nKey={`pharmacyLgoConnection.panelistConnectionSteps.offisante.ifMessagePersist`}
                    components={[<strong />]}
                  />
                </Box>
              )}
          </Box>
        </Stack>
      }
      color="error"
      sx={{ mt: 2 }}
      textProps={{ variant: 'body2' }}
    />
  );
