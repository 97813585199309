import { Box, Stack } from '@mui/system';
import { Banner } from 'components/banner/banner';
import { ClockFilledIcon } from 'components/icons';
import { Trans } from 'react-i18next';
import { PharmacyPanelist } from 'types/api/pharmacies/panelist_connection';
import { PanelistConnectionSteps } from '../PanelistConnectionSteps';

type Props = {
  pharmacyPanelist: PharmacyPanelist;
};

export const ConnectionEstablishingBanner = ({ pharmacyPanelist }: Props) => (
  <Banner
    icon={<ClockFilledIcon fontSize="medium" sx={{ marginRight: 1 }} />}
    title={
      <Stack gap={2}>
        <Box>
          <Trans
            i18nKey={'pharmacyLgoConnection.connectionEstablishing'}
            components={[<strong />]}
          />
        </Box>
        <Box>
          {pharmacyPanelist.panelist && (
            <PanelistConnectionSteps
              panelist={pharmacyPanelist.panelist}
              onboardingUrl={pharmacyPanelist.onboarding_url}
            />
          )}
        </Box>
      </Stack>
    }
    color="warning"
    sx={{ mt: 2 }}
    textProps={{ variant: 'body2' }}
  />
);
