export const MAX_INTEGER_VALUE = 2147483647;

export const multiplyByHundredWithScalePrecision = (
  value?: number | null,
  toFixedValue?: number
) => (value ? +value.toFixed(toFixedValue) * 100 : null);

export const divideByHundred = (value?: number | null) => (value ? value / 100 : null);

export const calculatePercentage = (count: number, totalCount: number, toFixedValue = 0) =>
  totalCount > 0 ? ((count / totalCount) * 100).toFixed(toFixedValue) : 0;

export const permyriadToPercent = (value: number) => value / 100;
