import { Box, Button, Card, Stack, Typography, useTheme } from '@mui/material';
import { SimpleDialogBox } from 'components/SimpleDialogBox';
import { Banner } from 'components/banner/banner';
import { InfoIconMUI2 } from 'components/icons';
import { StyledLink } from 'components/link/styles';
import { useTranslation } from 'locales/i18n';
import { startCase } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { GetPanelistConnectionStatusResponse } from 'types/api/pharmacies/panelist_connection';
import { LGO_LINKS } from 'utils/linkUtils';
import { PanelistConnectionStatusBanner } from './PanelistStatusComponents';
import { BlackTypo } from './styles';

type Props = {
  panelistConnection: GetPanelistConnectionStatusResponse;
  handleOnReset: () => void;
  disableDisconnectButton: boolean;
  isLoading: boolean;
};

export const PanelistConnectedCard = ({
  panelistConnection,
  handleOnReset,
  disableDisconnectButton,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Card sx={{ p: '25px' }}>
      <Typography variant="body2" fontWeight={700} color={theme.palette.grey[500]}>
        {t('shared.panelistConnection').toUpperCase()}
      </Typography>

      <PanelistConnectionStatusBanner panelistConnection={panelistConnection} />

      <Banner
        title={
          <Stack gap={1}>
            <BlackTypo variant="subtitle1">
              {startCase(panelistConnection.pharmacy_panelist?.panelist || '')}
            </BlackTypo>
            <Box display="flex" alignItems="center" justifyContent={'space-between'}>
              <Stack gap={1}>
                <Box display="flex">
                  <Typography>{t('pharmacyLgoConnection.acceptedDate')} :</Typography>
                  <BlackTypo sx={{ ml: 0.5 }}>
                    {moment(panelistConnection.pharmacy_panelist?.accepted_date).format('LL')}
                  </BlackTypo>
                </Box>
                <Box display="flex">
                  <Typography>{t('pharmacyLgoConnection.type')} :</Typography>
                  <BlackTypo sx={{ ml: 0.5 }}>{t('pharmacyLgoConnection.selloutData')}</BlackTypo>
                </Box>
              </Stack>

              <Button
                color="error"
                onClick={() => setIsModalOpen(true)}
                disabled={disableDisconnectButton || isLoading}
              >
                {t('account.logout')}
              </Button>
            </Box>
          </Stack>
        }
        color="grey"
        sx={{ p: '25px', mt: 2 }}
      />
      <Banner
        icon={<InfoIconMUI2 fontSize="medium" sx={{ marginRight: 1 }} />}
        title={
          <>
            <Box>{t('pharmacyLgoConnection.faksDataPolicy')}</Box>
            <StyledLink href={LGO_LINKS.INTERCOM} target="_blank">
              {t('pharmacyLgoConnection.dataPolicyKnowMore')}
            </StyledLink>
          </>
        }
        color="info"
        sx={{ maxWidth: { lg: '40%' }, mt: 2 }}
      />

      {isModalOpen && (
        <SimpleDialogBox
          handleClose={() => setIsModalOpen(false)}
          title={t('account.logout')}
          description={
            <Box marginBottom={-3}>
              <Typography>{t('pharmacyLgoConnection.askLogout')}</Typography>
              <Typography>{t('pharmacyLgoConnection.lgoDataWontBeUploaded')}</Typography>
            </Box>
          }
          cancelButton={{
            label: t('shared.cancel'),
            action: () => setIsModalOpen(false),
          }}
          actionButton={{
            label: t('shared.confirm'),
            action: handleOnReset,
            color: 'error',
            disabled: isLoading,
            isLoading: isLoading,
          }}
        />
      )}
    </Card>
  );
};
