import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Link, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useCreateMutation } from 'api';
import { FormProvider, TextField } from 'components/hook-form';
import { useOnLogin } from 'hooks/useOnLogin';
import { useTranslation } from 'locales/i18n';
import { Dispatch, FC, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { LoginPaths } from 'routes/paths';
import { requiredString } from 'utils/yupValidators';
import * as Yup from 'yup';
import { Step } from './VerifyEmail';

type FormValuesProps = {
  password: string;
};

type Props = {
  submittedEmail: string;
  setStep: Dispatch<SetStateAction<Step>>;
};

export const PasswordForm: FC<Props> = ({ submittedEmail, setStep }) => {
  const { t } = useTranslation();
  const onLogin = useOnLogin();

  const formSchema = Yup.object().shape({
    password: requiredString(),
  });

  const defaultValues = {
    password: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const authenticateMutation = useCreateMutation('authenticate');

  const authenticateWithPassword = async (data: FormValuesProps) => {
    authenticateMutation
      .mutateAsync({ email: submittedEmail, password: data.password })
      .then((data) => {
        if (data.success) {
          onLogin(data.auth_token);
        } else {
          if (data.reason === 'invalid_credentials') {
            toast.error(t('login.verify_email.incorrect_credentials'));
          } else if (data.reason === 'user_deactivated') {
            toast.error(t('login.userDeactivated'));
          }
        }
      });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(authenticateWithPassword)}>
      <Stack spacing={3}>
        <Typography>
          {t('login.verify_email.form.enter_password_for', { email: submittedEmail })}
          <Link sx={{ marginLeft: 2 }} onClick={() => setStep('email')}>
            {t('login.verify_email.form.modify_email')}
          </Link>
        </Typography>
        <TextField
          name="password"
          label={t('login.verify_email.form.your_password')}
          placeholder={t('login.verify_email.form.enter_your_password')}
          required
          type="password"
        />
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'end' }}>
          <Link href={LoginPaths.PasswordReset}>{t('resetPassword.forgotPassword')}</Link>
        </Box>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ mt: 3 }}
        >
          {t('shared.connect')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};
