export enum PharmacyPaths {
  Claims = '/pharmacy/claims',
  Claim = '/pharmacy/claims/:id',
  ClaimNewPage = '/pharmacy/claims/new-claim',
  Group = '/pharmacy/group',
  Trades = '/pharmacy/trades',
  Trade = '/pharmacy/trades/:id',
  TradeNew = '/pharmacy/trades/new',
  Orders = '/pharmacy/orders',
  Order = '/pharmacy/orders/:id',
  NewOrder = '/pharmacy/orders/new',
  OrdersRestocking = '/pharmacy/orders/restocking',
  LabsPharmacies = '/pharmacy/labs_pharmacies',
  LabsPharmacy = '/pharmacy/labs_pharmacies/:id',
  LabsDiscussions = '/pharmacy/labs_discussions',
  LabDiscussion = '/pharmacy/labs_discussions/:id',
  EditCurrentPharmacy = '/user/pharmacy',
  Stripe = '/pharmacy/stripe',
  StripeBillingForm = '/pharmacy/stripe/billing',
  LgoSetting = '/pharmacy/lgo_setting',
  ExpiredProductsRequest = '/pharmacy/claims/expired_products_request/:id',
  NewExpiredProductsRequest = '/pharmacies/:pharmacy_id/expired_products_request/:id',
  PharmacyEdit = '/pharmacy/:id/edit',
  Compensations = '/pharmacy/compensations',
}
