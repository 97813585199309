export enum LabPaths {
  TradesDashboard = '/lab/trades/dashboard',
  Trades = '/lab/trades/list',
  ClaimsDashboard = '/lab/claims/dashboard',
  ClaimsList = '/lab/claims/list',
  // Warning: We will have to change the path below to /lab/claims/:id, but preserving the redirection for old secured links
  Claim = '/lab/claims/list/:id',
  // Kept for backward compatibility with sent emails
  SecuredClaimOld = '/lab/claims/:id',
  SecuredLabClaim = '/secured/claims/:id',
  ClaimNew = '/lab/claims/new',
  ExpiredProductsRequestsDashboard = '/lab/expired_products_requests/dashboard',
  ExpiredProductsRequestsList = '/lab/expired_products_requests/list',
  ExpiredProductsRequest = '/lab/expired_products_requests/:id',
  Pharmacies = '/lab/pharmacies',
  Pharmacy = '/lab/pharmacies/:id',
  GroupsLabs = '/lab/groups_labs',
  GroupsLabEdit = '/lab/groups_labs/:id/edit',
  GuideItems = '/lab/guide_items',
  GuideItemNew = '/lab/guide_items/new',
  GuideItemEdit = '/lab/guide_items/:id/edit',
  EditLab = '/lab/:id/edit',
  RegionNew = '/lab/regions/new',
  RegionEdit = '/lab/regions/:id/edit',
  TopicNew = '/lab/topics/new',
  TopicEdit = '/lab/topics/:id/edit',
  SecuredAccessTrades = '/securedaccess/trades',
  SecuredAccessDashboard = '/securedaccess/dashboard',
  PharmacyLists = '/lab/pharmacies/lists',
  PharmacyListNew = '/lab/pharmacies/lists/new',
  PharmacyList = '/lab/pharmacies/lists/:id',
  OrdersList = '/lab/orders',
  Order = '/lab/orders/:id',
  ProductsList = '/lab/products',
  MasterTrades = '/lab/trades/master_trades',
  MasterTradeNew = '/lab/trades/master_trades/new',
  MasterTrade = '/lab/trades/master_trades/:id',
}
