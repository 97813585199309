import { Banner } from 'components/banner/banner';
import { CheckRoundedIcon } from 'components/icons';
import { Trans } from 'react-i18next';

export const ConnectionEstablishedBanner = () => (
  <Banner
    icon={<CheckRoundedIcon fontSize="medium" sx={{ marginRight: 1 }} />}
    title={
      <Trans i18nKey={'pharmacyLgoConnection.connectionEstablished'} components={[<strong />]} />
    }
    color="success"
    sx={{ maxWidth: { lg: '40%' }, mt: 2 }}
  />
);
